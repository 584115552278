@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("fonts/MaterialIcons-Regular.ttf");
}
.icon {
  font-family: "Material Icons";
}

/*# sourceMappingURL=index.css.map */